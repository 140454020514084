<template>
  <b-card>
    <div>
      <div class="d-flex align-items-center justify-center pb-1">
        <feather-icon icon="UserIcon" size="16" />
        <h4 class="pl-1" style="padding-top: 8px">{{ $t('general_info') }}</h4>
      </div>
      <b-row>
        <b-col cols="6"
          ><vi-input
            v-model="user.firstName"
            :label="$t('firstname')"
          ></vi-input
        ></b-col>
        <b-col cols="6"
          ><vi-input v-model="user.lastName" :label="$t('lastname')"></vi-input
        ></b-col>
      </b-row>
      <b-row>
        <b-col cols="6"
          ><vi-input
            v-model="user.password"
            type="password"
            :label="$t('password')"
          ></vi-input
        ></b-col>
        <b-col cols="6"
          ><vi-input
            v-model="user.repeatPassword"
            :label="$t('repeat-password')"
            type="password"
          ></vi-input
        ></b-col>
      </b-row>
      <b-row>
        <b-col cols="6"
          ><vi-select
            rules="required"
            :options="languages"
            v-model="user.languageId"
            :label="$t('language')"
          ></vi-select>
        </b-col>
      </b-row>
      <b-button @click="confirm" variant="primary" class="mt-2">{{
        $t('save')
      }}</b-button>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {
  BCard,
  BFormInput,
  BFormGroup,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue';
import ViInput from '../components/ViInput.vue';
import ViSelect from '../components/ViSelect.vue';
export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    ViInput,
    BRow,
    BCol,
    BButton,
    ViSelect,
  },
  async mounted() {
    const langResponse = await this.$store.dispatch('users/GET_LANGUAGES');
    this.languages = langResponse.data.map(l => {
      return {
        code: l.id,
        label: this.$i18n.t('languages.' + l.language_code),
        language_code: l.language_code,
      };
    });

    const response = await this.$store.dispatch('users/GET_PROFILE', {
      userId: this.$store.state.auth.userData.userId,
    });
    this.user = response.data;
  },
  data() {
    return {
      user: {
        firstName: null,
        lastName: null,
        password: null,
        repeatPassword: null,
        languageId: null,
      },
      languages: [],
    };
  },
  methods: {
    async confirm() {
      if (
        this.user.password &&
        this.user.password != this.user.repeatPassword
      ) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('warning'),
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            text: this.$t('profile.no_matching_password'),
          },
        });
      } else {
        let { password, repeatPassword, ...u } = this.user;
        if (this.user.password) u.password = this.user.password;

        this.user.repeatPassword = null;
        this.user.password = null;

        const response = await this.$store.dispatch('users/UPDATE_PROFILE', {
          userId: this.$store.state.auth.userData.userId,
          user: u,
        });

        const language = this.languages.find(
          l => l.code == this.user.languageId,
        );
        this.$i18n.locale = language.language_code;
        this.$store.commit('auth/SET_USER_LANG', language.language_code);

        if (response) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('success'),
              icon: 'AlertTriangleIcon',
              variant: 'success',
              text: this.$t('profile.updated'),
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('failed'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: this.$t('profile.failed'),
            },
          });
        }
      }
    },
  },
};
</script>

<style></style>
